import { Card, CardFooter } from 'reactstrap';
import Moment from 'react-moment';
import React, { useCallback } from 'react';
import {
	faCheckCircle,
	faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import {
	CardContainer,
	CardTitleLine,
	CheckMarkIcon,
	FilePreview,
	FileSizeLabel,
	FileTitle,
	OverlayButton,
} from './asset-card.styled-components';
import { useModalCreator, useModalRenderer } from '../../../utils/ModalStack';
import AssetCardThumbnail from './asset-card-thumbnail.component';
import {
	Creatable,
	getCreationDateOf,
} from '../../../utils/models/CreatableEntityModel';
import AssetDetailsDialog from '../asset-details-dialog.component';
import InsightsProvider from '../../../utils/core/InsightsProvider';
import { AssetVersion } from '../../workflow/workflows/types/workflow.types';
import {
	AlgoliaAssetVersion,
	CollectionID,
} from './asset-infinite-hits.component';
import { useAuthContext } from 'utils/auth';
import { humanReadableFileSizeSI } from 'utils/common';
import { InteractiveFontAwesomeIcon, SmallText } from 'components/index';
import { PaddedBadge } from 'components/dashboard.component/components/workflow-health-flag.component';
import { useAccounts } from 'context/useAccounts';

interface AssetCardProps {
	assetVersion: AlgoliaAssetVersion | AssetVersion;
	collectionId?: CollectionID;
	href?: string;
	isLinkEnabled?: boolean;
	disableDetail?: boolean;
	isSelected?: boolean;
	showDownloadOption?: boolean;
	downloadOption?: (fileName: string) => void;
	onClick?: (asset: AlgoliaAssetVersion) => void;
	type?: string;
	workflowId?: string;
	workflowOwner?: string;
	isEditedByCreatorOnly?: boolean;
	hideCustomPreview?: boolean;
	showUploadedDate?: boolean;
	onDelete?: () => void;
	onRemoveFromCollection?: () => void;
}

export const useExtractPreview = () => {
	const { defaultAct } = useAccounts();
	const extractPreviewURL = (asset: AssetVersion) => {
		if (!asset) return '';
		if (asset?.path?.startsWith('workflows/')) {
			return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/w/${
				defaultAct?._id
			}/${asset.preview?.replace('_preview/', '').replace('.jpg', '')}`;
		}
		if (asset?.customPreviewURL) {
			return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/d/${
				defaultAct?._id
			}/${asset.customPreviewURL.replace('_preview/', '').replace('.jpg', '')}`;
		}
		let previewURL = asset?.preview?.includes('/preview/d/')
			? `${process.env.REACT_APP_ROME_API_ENDPOINT}${asset?.preview}`
			: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/d/${
					defaultAct?._id
			  }/${asset?.preview?.replace('_preview/', '').replace('.jpg', '')}`;

		if (asset?.preview) return previewURL;

		if (asset?.previewURL) {
			return asset.previewURL;
		}

		return '';
	};
	return { extractPreviewURL };
};
export const extractPreviewURL = (asset: AssetVersion) => {
	if (!asset) return '';

	if (asset?.path?.startsWith('workflows/')) {
		return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/w/${
			asset.account
		}/${asset.preview?.replace('_preview/', '').replace('.jpg', '')}`;
	}
	if (asset?.customPreviewURL) {
		return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/d/${
			asset.account
		}/${asset.customPreviewURL.replace('_preview/', '').replace('.jpg', '')}`;
	}
	let previewURL = asset?.preview?.includes('/preview/d/')
		? `${process.env.REACT_APP_ROME_API_ENDPOINT}${asset?.preview}`
		: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/d/${
				asset?.account
		  }/${asset?.preview?.replace('_preview/', '').replace('.jpg', '')}`;

	if (asset?.preview) return previewURL;

	if (asset?.previewURL) {
		return asset.previewURL;
	}

	return '';
};
const AssetCard = (props: AssetCardProps) => {
	const userStore = useAuthContext();

	const getAssetColor = (extension: string) => {
		switch (extension) {
			case 'zip':
				return 'warning';

			case 'jpeg':
			case 'jpg':
			case 'png':
			case 'tif':
				return 'primary';

			case 'pdf':
			case 'ai':
			case 'eps':
				return 'danger';

			default:
				return 'secondary';
		}
	};

	const insightsProvider = React.useMemo(() => new InsightsProvider(), []);
	const {
		assetVersion: asset,
		isLinkEnabled,
		isSelected,
		onClick,
		type,
		hideCustomPreview,
		disableDetail,
	} = props;
	const modalStack = useModalCreator();
	const modalRenderer = useModalRenderer();
	const clickCallBack = useCallback(() => {
		if (onClick) {
			onClick(asset as AlgoliaAssetVersion);
		}
	}, [asset, onClick]);

	const buildClassList = () => {
		let classList = `asset-card`;
		if (isSelected) {
			classList = `${classList} selected`;
		}
		return classList;
	};

	const openDetailsModal = useCallback(
		(asset: AlgoliaAssetVersion, type: string) => {
			insightsProvider.sendInsights(
				'CLICK_HIT',
				userStore.currentUser,
				asset,
				'',
				'convertedObjectIDs'
			);
			if (disableDetail) {
				clickCallBack();
				return;
			}

			// if disableDetail is false Keep detail enabled
			if (isLinkEnabled && type === 'uploaded') {
				modalStack.addModal(
					<AssetDetailsDialog
						onDelete={props.onDelete!}
						onRemoveFromCollection={props.onRemoveFromCollection}
						shouldFetch={true}
						collectionId={props.collectionId}
						workflowId={props.workflowId}
						workflowOwner={props.workflowOwner}
						assetId={asset._id}
						assetToPreview={asset}
					/>
				);
				modalRenderer.renderCurrentModal();
			} else if (isLinkEnabled) {
				modalStack.addModal(
					<AssetDetailsDialog
						onDelete={props.onDelete!}
						onRemoveFromCollection={props.onRemoveFromCollection}
						shouldFetch={false}
						collectionId={props.collectionId}
						assetToPreview={asset}
						workflowId={props.workflowId}
						workflowOwner={props.workflowOwner}
						isEditedByCreatorOnly={props.isEditedByCreatorOnly}
						assetId={asset._id}
					/>
				);
			}
		},
		//eslint-disable-next-line
		[
			modalStack,
			props.workflowId,
			props.collectionId,
			insightsProvider,
			userStore.currentUser,
			props.onDelete,
		]
	);

	const isButton = () => {
		if (isLinkEnabled === undefined) {
			return false;
		} else {
			return !isLinkEnabled;
		}
	};

	const MimeTypeBadge = () => (
		<div style={{ float: 'right' }}>
			<PaddedBadge
				color={getAssetColor(
					asset.extension
						? asset.extension.toLowerCase()
						: asset.fileName.slice(-3)
				)}
			>
				{asset.extension
					? asset.extension.toLowerCase()
					: asset.fileName.slice(-3).toLowerCase()}
			</PaddedBadge>
		</div>
	);

	const { extractPreviewURL: extractPreview } = useExtractPreview();
	const renderAssetCard = () => (
		<Card
			onClick={() => openDetailsModal(asset as AlgoliaAssetVersion, '')}
			className="h-100 mt-2"
		>
			<FilePreview>
				<AssetCardThumbnail
					hideCustomPreview={hideCustomPreview}
					className="file-thumbnail"
					src={extractPreview(asset)}
					alt={asset?.fileName}
					asset={asset}
				/>
			</FilePreview>
			<CardFooter className="bg-white">
				<CardTitleLine>
					<FileTitle>{asset?.title || asset?.fileName}</FileTitle>
					<FileSizeLabel>
						{humanReadableFileSizeSI(asset?.fileSizeBytes)}
					</FileSizeLabel>
				</CardTitleLine>
				<SmallText>
					{props.showUploadedDate && (
						<>
							Uploaded &nbsp;
							<Moment
								fromNow
								date={getCreationDateOf((asset as any) as Creatable)}
							/>
						</>
					)}
				</SmallText>
				{props.showDownloadOption && props.downloadOption && (
					<InteractiveFontAwesomeIcon
						id={'downloadAsset' + asset.fileName}
						onClick={() => {
							if (props.downloadOption) props.downloadOption(asset.fileName);
						}}
						icon={faFileDownload}
					/>
				)}

				{/* <FileIcon>
					<PrivacyLabel className="ml-2">{asset?.privacy}</PrivacyLabel>
				</FileIcon> */}
			</CardFooter>
		</Card>
	);

	const renderOriginalVersionCard = () => (
		<Card
			onClick={() => openDetailsModal(asset as AlgoliaAssetVersion, 'original')}
			className="h-100"
		>
			<FilePreview>
				<AssetCardThumbnail
					className="file-thumbnail"
					src={
						asset.originalPreviewURL
							? asset.originalPreviewURL
							: asset.previewURL
					}
					alt={asset?.fileName}
					asset={asset}
				/>
			</FilePreview>
			<CardFooter className="bg-white">
				<CardTitleLine>
					<FileTitle>{asset?.title || asset?.fileName}</FileTitle>
					<FileSizeLabel>
						{humanReadableFileSizeSI(asset?.fileSizeBytes)}
					</FileSizeLabel>
				</CardTitleLine>
				<CardTitleLine>
					{props.showUploadedDate && (
						<small>
							Uploaded{' '}
							<Moment
								fromNow
								date={getCreationDateOf((asset as any) as Creatable)}
							/>
						</small>
					)}
					{props.showDownloadOption && props.downloadOption && (
						<InteractiveFontAwesomeIcon
							id={'downloadAsset' + asset.fileName}
							onClick={() => {
								if (props.downloadOption) props.downloadOption(asset.fileName);
							}}
							icon={faFileDownload}
						/>
					)}
					<MimeTypeBadge />
				</CardTitleLine>
			</CardFooter>
		</Card>
	);

	const renderUploadedAssetCard = () => (
		<Card
			onClick={() => openDetailsModal(asset as AlgoliaAssetVersion, 'uploaded')}
			className="h-100"
		>
			<FilePreview>
				<AssetCardThumbnail
					className="file-thumbnail"
					src={extractPreviewURL(asset)}
					alt={asset?.fileName}
					asset={asset}
				/>
			</FilePreview>
			<CardFooter className="bg-white">
				<CardTitleLine>
					<FileTitle>{asset?.title || asset?.fileName}</FileTitle>
					<FileSizeLabel>
						{humanReadableFileSizeSI(asset?.fileSizeBytes)}
					</FileSizeLabel>
				</CardTitleLine>
				<CardTitleLine>
					{props.showUploadedDate && (
						<small>
							Uploaded{' '}
							<Moment
								fromNow
								date={getCreationDateOf((asset as any) as Creatable)}
							/>
						</small>
					)}
					{props.showDownloadOption && props.downloadOption && (
						<InteractiveFontAwesomeIcon
							id={'downloadAsset' + asset.fileName}
							onClick={() => {
								if (props.downloadOption) props.downloadOption(asset.fileName);
							}}
							icon={faFileDownload}
						/>
					)}
					<MimeTypeBadge />
				</CardTitleLine>
			</CardFooter>
		</Card>
	);

	const renderLink = () => {
		switch (type) {
			case 'uploaded':
				return renderUploadedAssetCard();
			case 'original':
				return renderOriginalVersionCard();
			default:
				return renderAssetCard();
		}
	};

	const renderButton = () => (
		<OverlayButton onClick={clickCallBack}></OverlayButton>
	);

	const render = () => (
		<CardContainer className={buildClassList()}>
			{renderLink()}
			{isButton() ? renderButton() : null}
			{isSelected ? <CheckMarkIcon icon={faCheckCircle} size="lg" /> : null}
		</CardContainer>
	);

	return render();
};

export default AssetCard;
