import { Form, ButtonGroup } from 'reactstrap';
import React, {
	ChangeEvent,
	FormEvent,
	useState,
	useCallback,
	useContext,
} from 'react';

import { _logError } from '../../../utils/common/log';
import {
	EntityPropList,
	EntityPropListItem,
} from '../../entity-details.styled-components';
import { LabeledInput, SubmitButton, LabeledSelect } from '../../forms';
import themeStore from '../../../core-ui/models/ThemeStore';

import { navigate } from '@reach/router';
import RenderWhen from '../../render-when.component';
import { useModalCloser, useModalCreator } from '../../../utils/ModalStack';
import DeleteAssetDialog from './delete-asset-dialog.component';
import {
	AssetVersion,
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { useAuthContext } from '../../../utils/auth';
import { useAxios } from '../../../hooks';

import { NotificationsContext } from '../../notifications';
import EntityMetadataForm from 'components/metadata/components/entity-metadata-form.component';
import { extractPreviewURL } from './asset-card.component';

const assetTerm = themeStore._.asset.toLowerCase();

const loadingLabel = 'Loading...';

interface Props {
	asset: AssetVersion;
	hideUpdateButtons?: boolean;
	generatedPreview?: string;
	onUpdatePreview: (previewURL: string) => void;
	onChange?: (updated: AssetVersion) => void;
	showReplaceModal?: () => void;
}

const EditAssetForm = (props: Props) => {
	const { asset } = props;
	const modalCloser = useModalCloser();
	const { currentUser } = useAuthContext();
	const { info } = useContext(NotificationsContext);
	const assetStore = useAxios<AssetVersion>('assets');
	const modalStack = useModalCreator();

	const [updatedAsset, setUpdatedAsset] = useState(asset);

	const onChange = (event: ChangeEvent<any>) => {
		const { name, value } = event.target;
		if (props.onChange) props.onChange({ ...updatedAsset, [name]: value });
		setUpdatedAsset({ ...updatedAsset, [name]: value });
	};

	const archiveOptions = [
		{ value: `${true}`, option: 'Archived' },
		{ value: `${false}`, option: 'Active' },
	];

	const setArchived = (event: ChangeEvent<any>) => {
		const archived = event.target.value === 'true';
		if (props.onChange) props.onChange({ ...updatedAsset, archived: archived });
		setUpdatedAsset({ ...updatedAsset, archived: archived });
	};

	const setPreviewToUse = (id: string) => {
		if (id === asset._id) {
			setUpdatedAsset({
				...updatedAsset,
				previewURL: asset?.originalPreviewURL as string,
				preview: asset.preview as string,
				customPreviewURL: '',
			});
			props.onUpdatePreview(
				extractPreviewURL({
					...asset,
					preview: asset.preview as string,
					previewURL: asset.originalPreviewURL as string,
					customPreviewURL: '',
				})
			);
		} else {
			const previewToUse = [...asset.versions]?.find((a) => a._id === id);
			if (previewToUse) {
				setUpdatedAsset({
					...updatedAsset,
					previewURL: extractPreviewURL(previewToUse),
					preview: previewToUse?.preview,
					customPreviewURL: previewToUse?.customPreviewURL,
				});
				props.onUpdatePreview(
					{
						...updatedAsset,
						previewURL: extractPreviewURL(previewToUse),
						preview: previewToUse?.preview,
						customPreviewURL: previewToUse?.customPreviewURL,
					}.previewURL
				);
			}
		}
	};

	const updateAssetSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const href = window.location.href;
			const wfId = href.slice(href.indexOf('workflow')+9,href.indexOf('edit')-1);

			if(href.includes('workflow'))
			{
				await assetStore.updateOne(asset._id, { ...updatedAsset },wfId);
				info(`${themeStore._.asset} saved!`);
				navigate(`/admin/dam/assets/${asset._id}/workflow/${wfId}/edit`, { replace: true });
			}
			else
			{
				await assetStore.updateOne(asset._id, { ...updatedAsset });
				info(`${themeStore._.asset} saved!`);
				navigate(`/admin/dam/assets/${asset._id}`, { replace: true });
				
			}
				

		} catch (error) {
			_logError(error);
			error(
				`An issue occurred while updating ${assetTerm.toLowerCase()}. Please try again later.`
			);
		}

		return false;
	};

	/**
	 * Deletes the relevant asset from both algolia and the database
	 * by utilizing the asset store which hits the API
	 * @param event Form event that is submitted in terms of deleting said asset
	 */
	const deleteAssetSubmit = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			try {
				await assetStore.deleteOne(asset._id);
				info(`${themeStore._.asset} deleted!`);
				modalCloser.closeModal();
				return navigate('/admin/dam/assets');
			} catch (error) {
				_logError(error);
				error(
					`An issue occurred while updating ${assetTerm.toLowerCase()}. Please try again later.`
				);
			}

			return false;
		},
		[assetStore, modalCloser, asset._id, info]
	);

	const onMetadataChange = (updatedMeta: EntityMetadata) => {
		setUpdatedAsset({ ...updatedAsset, metadata: updatedMeta });
		if (props.onChange)
			props.onChange({ ...updatedAsset, metadata: updatedMeta });
	};

	const getSelectedPreviewURL = () => {
		if (asset?.originalPreviewURL === updatedAsset.preview) return asset?._id;
		return [...asset.versions]?.find(
			(a) => extractPreviewURL(asset) === extractPreviewURL(a)
		)?._id;
	};

	/**
	 * Callback function that will open up the delete modal
	 * allowing user chance to confirm before calling the API to delete asset
	 */
	const openDeleteModal = useCallback(() => {
		modalStack.addModal(
			<DeleteAssetDialog asset={asset} onDelete={deleteAssetSubmit} />
		);
	}, [asset, deleteAssetSubmit, modalStack]);

	const render = () => (
		<Form>
			<EntityPropList>
				<EntityPropListItem>
					<LabeledInput
						label="Display name"
						type="text"
						name="title"
						value={updatedAsset.title}
						onChange={onChange}
						readOnly={updatedAsset.title === loadingLabel}
					/>
				</EntityPropListItem>
				<EntityPropListItem>
					<LabeledSelect
						id="assetStatus"
						label="Asset Status"
						value={updatedAsset.archived ? 'true' : 'false'}
						name="archived"
						onChange={setArchived}
					>
						{archiveOptions.map((opt) => (
							<option key={opt.option} value={opt.value}>
								{opt.option}
							</option>
						))}
					</LabeledSelect>
				</EntityPropListItem>
				{/* TODO: enable editing privacy property (RRR-139) */}
			</EntityPropList>
			<div className="my-2">
				{asset.versions && asset.versions?.length > 0 && (
					<LabeledSelect
						id="assetPreviewSelect"
						label="Asset Preview to use"
						value={getSelectedPreviewURL()}
						className="form-control"
						onChange={(e) => setPreviewToUse(e.target.value)}
					>
						{[updatedAsset, ...asset.versions]?.map((a) => (
							<option key={a._id} value={a._id}>
								{a.fileName}
							</option>
						))}
					</LabeledSelect>
				)}
			</div>
			<div>
				<EntityMetadataForm
					displayTemplateField={false}
					metadata={{
						...updatedAsset.metadata,
						fieldOptions: updatedAsset?.metadataTemplateUsed
							? (updatedAsset?.metadataTemplateUsed as EntityMetadataTemplate)
									?.fieldOptions
							: updatedAsset.metadata.fieldOptions,
					}}
					update={(updated) => onMetadataChange(updated.payload)}
					onChange={(updated) => {
						if (updated) {
							onMetadataChange({
								...updatedAsset.metadata,
								fields: updated?.fields,
								fieldOptions: updated?.fieldOptions,
								fieldTypes: updated?.fieldTypes,
								values: updated?.values,
								tags: updated?.tags,
							});
						}
						return updated as EntityMetadata;
					}}
				/>
			</div>

			<RenderWhen when={!props.hideUpdateButtons}>
				<ButtonGroup>
					<RenderWhen when={!window.location.href.includes('workflow')}>
						<SubmitButton
							onClick={updateAssetSubmit}
							label={`Update ${assetTerm}`}
							type="button"
						/>
					</RenderWhen>
					<RenderWhen when={currentUser.isAdmin && !!asset.account}>
						<SubmitButton
							className="ml-1"
							onClick={() => openDeleteModal()}
							label={`Delete ${assetTerm}`}
							type="button"
						/>
					</RenderWhen>
					<RenderWhen when={!!props.showReplaceModal}>
						<SubmitButton
							className="ml-1"
							onClick={props.showReplaceModal}
							label={`Replace ${assetTerm}`}
							type="button"
						/>
					</RenderWhen>
				</ButtonGroup>
			</RenderWhen>
		</Form>
	);

	return render();
};

export default EditAssetForm;
