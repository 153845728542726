import algoliasearch from 'algoliasearch/lite';
import { getViewableDamFolders } from 'components/accounts/types';
import React, { Suspense, useCallback, useRef, useState } from 'react';
import { Configure, HitsPerPage, InstantSearch } from 'react-instantsearch-dom';
import { Col, Container, FormText, Row } from 'reactstrap';
import { useSearchHistory } from 'utils/useSearchHandler';
import themeStore from '../../core-ui/models/ThemeStore';
import { useAuthContext, useGroupContext } from '../../utils/auth';
import { UserAssetViewType } from '../accounts/models/UserStatus.model';
import CustomSearchBox from '../algolia/search-box.component';
import InfiniteHitsFolderView from '../dam-asset-collections/components/facet-tree-custom-hits.component';
import RenderWhen from '../render-when.component';
import SaveAlgoliaQueryButton from '../save-algolia-query-button.component';
import CustomAssetHits, {
	PermissionsObject,
} from './components/asset-infinite-hits.component';
import Loading from '../loading.component';

const assetTerm = themeStore._.asset.toLowerCase();
const searchInputPlaceholder = `Search all ${assetTerm}s...`;

/**
 * Algolia search client necessary to use algolia react-instantsearch library
 */
const searchClient = algoliasearch(
	`${process.env.REACT_APP_ALGOLIA_APP_ID}`,
	`${process.env.REACT_APP_ALGOLIA_API_KEY}`
);

const AssetsIndexView = () => {
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const [searchHistory, setSearchHistory] = useSearchHistory();
	const [isRefining, setIsRefining] = useState(
		currentUser?.persistSearchFilters && !!searchHistory.searchTerm
	);
	const instaRef = React.useRef<InstantSearch>();
	/**
	 * Sets the global state on if the user is refiningyar
	 * @param isRefining boolean value on if the user is refining, passed up from child components
	 * @returns void
	 */
	const isRefiningCallback = useCallback((isRefining: boolean) => {
		const list = (instaRef?.current?.state as any).contextValue.store.getState()
			.widgets.refinementList;
		let isTrulyRefining =
			Object.keys(list || {}).some((key) => Array.isArray(list[key])) ||
			(currentUser?.persistSearchFilters && searchHistory.searchTerm);
		setIsRefining(isTrulyRefining);

		//eslint-disable-next-line
	}, []);

	const onChange = (value: string) => {
		let isTrulyRefining = !!value;
		if (!isTrulyRefining) {
			const list = (instaRef?.current
				?.state as any).contextValue.store.getState().widgets.refinementList;
			if (list)
				isTrulyRefining = Object.keys(list || {}).some((key) =>
					Array.isArray(list[key])
				);
		}
		setIsRefining(isTrulyRefining);
		if (!isTrulyRefining) {
			setSearchHistory((history: any) => ({ refinements: [], searchTerm: '' }));
		} else {
			setSearchHistory((history: any) => ({ ...history, searchTerm: value }));
		}
	};

	/**
	 * Available refinements based on the users current permissions
	 */
	const permissionsObject = useRef<PermissionsObject>();
	/*
			Parse the current users view preference (folder vs search) for rendering of assets
		*/
	const preferences = (JSON.parse(
		localStorage.getItem('UserPreferences') as string
	) ?? { recievePushNotifications: false, viewPreference: 'searchView' }) as {
		recievePushNotifications: boolean;
		viewPreference: string;
	};
	const { viewPreference } = preferences;

	/**
			Concatenate the permissions of all the users groups into one distinct and unique object
		 */

	const availableRefinements = React.useMemo(() => {
		if (groupsForCurrentUser) {
			let retVal: string[] = [];
			groupsForCurrentUser.forEach((group, idx) => {
				if (idx === 0)
					permissionsObject.current = {
						brand: group.BrandPermissions,
						category: group.CategoryPermissions,
						archived: group.ArchivedPermissions,
						packageType: group.PackageTypePermissions,
						country: group.CountryPermissions,
						size: group.CountPermissions,
						die: group.DielinePermissions,
						printer: group.PrinterPermissions,
						variety: group.VarietyPermissions,
						filetype: group.FileTypePermissions,
					};
				else
					permissionsObject.current = {
						...permissionsObject.current,
						brand: [
							...(permissionsObject?.current?.brand ?? []),
							...group.BrandPermissions,
						],
						category: [
							...(permissionsObject?.current?.category ?? []),
							...group.CategoryPermissions,
						],
						size: [
							...(permissionsObject?.current?.size ?? []),
							...group.CountPermissions,
						],
						variety: [
							...(permissionsObject?.current?.variety ?? []),
							...group.VarietyPermissions,
						],
						packageType: [
							...(permissionsObject?.current?.packageType ?? []),
							...group.PackageTypePermissions,
						],
						filetype: [
							...(permissionsObject?.current?.filetype ?? []),
							...group.FileTypePermissions,
						],
						country: [
							...(permissionsObject?.current?.country ?? []),
							...group.CountryPermissions,
						],
						die: [
							...(permissionsObject?.current?.die ?? []),
							...group.DielinePermissions,
						],
						printer: [
							...(permissionsObject?.current?.printer ?? []),
							...group.PrinterPermissions,
						],
						archived: [
							...(permissionsObject?.current?.archived ?? []),
							...group.ArchivedPermissions,
						],
					};

				retVal.push(...getViewableDamFolders(group));
			});
			return retVal;
		}
	}, [groupsForCurrentUser]);

	return (
		<Suspense fallback={<Loading />}>
			<InstantSearch
				indexName={`${process.env.REACT_APP_ALGOLIA_DAM_INDEX}`}
				searchClient={searchClient}
				ref={(ref) => (instaRef.current = ref as InstantSearch)}
			>
				{/* in the event the user is refining, we'll render 100 assets based on refinements
						otherwise, rendering nothing until user refines */}
				<Configure
					advancedSyntax
					hitsPerPage={isRefining ? 25 : 0}
					clickAnalytics
				/>
				<Container fluid>
					<RenderWhen when={viewPreference === UserAssetViewType.searchView}>
						<Row>
							<Col md={9} className="pb-4">
								<CustomSearchBox
									onChange={onChange}
									placeholder={searchInputPlaceholder}
								/>
							</Col>
							<Col md={3} className="mt-md-0 mt-3">
								{false && (
									<SaveAlgoliaQueryButton
										className="float-md-right float-none"
										type={'assets'}
										sort={''}
										filters={['']}
										templates={''}
									/>
								)}
							</Col>
						</Row>
					</RenderWhen>
					<Row>
						<Col md={12} className="d-flex justify-content-end">
							<div className="w-100 text-right">
								<FormText
									className="pb-1 d-block text-right position-relative"
									style={{ right: 0 }}
								>
									Viewing{' '}
								</FormText>

								<HitsPerPage
									className="w-100 text-right"
									defaultRefinement={25}
									items={[
										{ value: 15, label: '15 Assets Per Page' },
										{ value: 25, label: '25 Assets Per Page' },
										{ value: 50, label: '50 Assets Per Page' },
										{ value: 100, label: '100 Assets Per Page' },
									]}
								/>
							</div>
						</Col>
					</Row>

					<hr />

					{viewPreference === UserAssetViewType.searchView ? (
						<CustomAssetHits
							// @ts-ignore
							permissionsObject={permissionsObject.current}
							availableRefinements={availableRefinements as string[]}
							isRefining={isRefining as boolean}
							isRefiningCallback={isRefiningCallback}
							searchHistory={searchHistory}
							setSearchHistory={setSearchHistory}
						/>
					) : (
						<InfiniteHitsFolderView
							// @ts-ignore
							permissionsObject={permissionsObject.current}
							availableRefinements={availableRefinements as string[]}
							isRefining={isRefining as boolean}
							isRefiningCallback={isRefiningCallback}
						/>
					)}
				</Container>
			</InstantSearch>
		</Suspense>
	);
};

export default AssetsIndexView;
