import { types } from 'mobx-state-tree';
import { Role } from 'context/PermissionsContext';

export enum UserRole {
	'SuperAdmin' = 1, // The real super admin, can do EVERYTHING
	'Developer' = 2, // New role, like RomeDevelopers but can edit any entity like workflows, templates, permissions, etc
	'WaaAdmin' = 3, // This to be renamed to WAA Admin
	'ClientAdmin' = 4, // Currently Site Admin, rename to Client Admin
	'ProjectManager' = 5,
	'User' = 6,
	'Observer' = 7,
	'DAMOnlyAdmin' = 8,
	'DAMOnlyNonAdmin' = 9,
	'Demo Role' = 10,
}

export const userRolesModel = types.enumeration(Object.keys(UserRole));

// Default to DAMOnlyNonAdmin
export const getUserRoleName = (userRole: Role) => {
	if (!userRole) return '';
	const { role: roleName } = userRole;
	return roleName || 'DAMOnlyNonAdmin';
};

// Roles with most privileges have higher index.
const sortedRoles = [
	UserRole.DAMOnlyAdmin,
	UserRole.DAMOnlyNonAdmin,
	UserRole.User,
	UserRole.Observer,
	UserRole.ProjectManager,
	UserRole.ClientAdmin,
	UserRole.WaaAdmin,
	UserRole.Developer,
	UserRole.SuperAdmin,
] as const;

const roleIndexByRole = sortedRoles.reduce(
	(roleIdxByRole, role: UserRole, idx) => {
		roleIdxByRole[role] = idx;
		return roleIdxByRole;
	},
	{} as Record<UserRole, number>
);

export const roleIsAtLeast = (
	required: UserRole,
	actual: UserRole = UserRole['Demo Role']
): boolean => roleIndexByRole[actual] >= roleIndexByRole[required];

export const isSiteAdmin = (roleId: UserRole): boolean =>
	roleIsAtLeast(UserRole.ClientAdmin, roleId);
