import * as axios from 'axios';
import {
	UserRole,
	roleIsAtLeast,
} from 'components/accounts/models/UserRole.model';
import { useUpload } from 'context/file-upload-provider.component';
import React from 'react';
import { Maybe } from 'types/globals';
import { User } from 'utils/auth';
import { getId } from '../../../../utils/common';
import { _logError } from '../../../../utils/common/log';
import { Multimap } from '../../../../utils/common/Multimap';
import {
	AssetCollection,
	AssetVersion,
	AssetVersionUploadData,
	EntityMetadata,
	InputSlot,
	Stage,
	Workflow,
} from '../../../workflow/workflows/types/workflow.types';
import { flattenStages } from '../../../../components/workflow/workflows/helpers/workflowStage.helpers';
import * as T from '../../../../components/workflow/workflows/types/workflow.types';
import { useWorkflowContext } from '../../../../context/useWorkflowStore';

export const buildEmptyAssetMetadata: EntityMetadata = {
	fieldOptions: {} as Record<string, { field: string; options: string[] }>,
	fieldTypes: Array<{ fieldType: string; field: string }>(),
	fields: Array<any>(),
	tags: Array<any>(),
	values: {} as { [key: string]: string },
} as EntityMetadata;

export const useAssetHelper = () => {
	const { findOne: findWorkflow } = useWorkflowContext();
	const { clearUploadProgress, updateUploadProgress } = useUpload();
	const endpoint = process.env.REACT_APP_ROME_API_ENDPOINT as string;
	const token = localStorage.getItem('rome_auth') as string;
	const config = React.useMemo(
		() =>
			token && JSON.parse(token)?.accessToken
				? {
						headers: {
							Authorization: `Bearer ${JSON.parse(token).accessToken}`,
						},
				  }
				: { headers: {} },
		[token]
	);

	const getHeaders = React.useCallback(() => {
		let authHeaders = { headers: config?.headers } as any;
		if (
			!!authHeaders?.headers?.Authorization?.includes('null') ||
			!authHeaders?.headers?.Authorization
		) {
			authHeaders = {
				headers: {
					maxContentLength: Infinity,
					maxBodyLength: Infinity,
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		return authHeaders;
	}, [config]);

	const downloadFileWithinAsset = async (id: string, fileName: string) => {
		const downloadAction = await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/download/zipped/${fileName}/${id}`,
			{
				headers: { ...getHeaders().headers, Accept: 'application/pdf' },
				responseType: 'arraybuffer',
			}
		);

		const url = window.URL.createObjectURL(new Blob([downloadAction?.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName); //or any other extension
		document.body.appendChild(link);
		link.click();
		link.remove();
		return true;
	};

	const removeAssetFromCollection = async (
		assetId: string,
		collectionId: string
	) => {
		const patched = await axios.default.patch(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/${assetId}/${collectionId}`,
			{},
			getHeaders()
		);
		return { success: patched.status === 200 };
	};

	const replaceAsset = async (id: string, file: File) => {
		const href = window.location.href;
		const wfId = href.slice(
			href.indexOf('workflow') + 9,
			href.indexOf('edit') - 1
		);

		const asset = await findOne(id);
		if (!href.includes('workflow')) {
			return await postFile(`/assets/replace/asset/${id}`, file);
		} else {
			const workflow = (await findWorkflow(wfId)) as Workflow;
			const flatStages = flattenStages(workflow as T.Flattenable) ?? [];
			const stage = flatStages.find(
				(stage: any) =>
					stage.inputSlots &&
					stage?.inputSlots?.some(
						(slot: { versions: any[] }) =>
							slot &&
							slot.versions &&
							slot.versions?.some(
								(version) => version._id.toString() === id.toString()
							)
					)
			);

			const slot = stage?.inputSlots?.find(
				(slot) =>
					slot &&
					slot.versions &&
					slot.versions?.some(
						(version) => version._id.toString() === id.toString()
					)
			);

			const version = slot?.versions?.find(
				(version) => version._id.toString() === id.toString()
			);
			const md5 = version?.md5;

			await postFile(
				`/assets/replace/asset/${id}/workflowId/${wfId}/md5/${md5}`,
				file
			);
			return;
		}
	};

	const downloadAssetPdf = async (id: string, fileName: string) => {
		const downloadAction = await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/download/pdf/${id}`,
			{
				headers: { ...getHeaders().headers, Accept: 'application/pdf' },
				responseType: 'arraybuffer',
			}
		);

		const url = window.URL.createObjectURL(new Blob([downloadAction.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName); //or any other extension
		document.body.appendChild(link);
		link.click();
		link.remove();
		return true;
	};

	const uploadVersion = async (
		file: File,
		data: AssetVersionUploadData,
		asset: AssetVersion
	) => {
		return await postFile('/assets/upload/version/' + asset._id, file, data);
	};

	const findOne = async (id: string) => {
		const asset = await axios.default.get<AssetVersion>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/` + id,
			getHeaders()
		);
		if (asset?.data) return asset?.data;
	};

	const runAssetTest = async (deletedFlag: string) => {
		return await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/testAssets/${deletedFlag}`,
			getHeaders()
		);
	};

	const runAssetPreviewReport = async () => {
		return await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/testAssetPreviews/`,
			getHeaders()
		);
	};

	const deletePreviewFromStorage = async (assetPaths: string[]) => {
		return await axios.default.patch(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/deletePreviewsFromStorage/`,
			assetPaths,
			getHeaders()
		);
	};

	const removeAssetsFromDB = async (assetPaths: string[]) => {
		return await axios.default.patch(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/removeAssetsFromDB/`,
			assetPaths,
			getHeaders()
		);
	};

	const runAssetCompare = async () => {
		const asset = await axios.default.get<any>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/compareAssets/`,
			getHeaders()
		);

		return asset;
	};

	const removeIndexes = async () => {
		await axios.default.get<any>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/removeIndexes/`,
			getHeaders()
		);

		return;
	};

	const updateAssetMeta = async () => {
		return await axios.default.get<AssetVersion[]>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/updateAssetMeta/`,
			getHeaders()
		);
	};

	const findVersion = async (id: string) => {
		const response = await axios.default.get<{
			asset: AssetVersion;
			version: AssetVersion;
		}>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/version/` + id,
			getHeaders()
		);
		if (response?.data) {
			const { version, asset } = response?.data;
			return { version, asset };
		}
	};

	const shareAssetToEmail = async (id: string, email: string) => {
		const response = await axios.default.put<{ success: boolean }>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/share/${email}/${id}`,
			{ data: JSON.stringify([]) },
			getHeaders()
		);
		return response.data.success;
	};

	const shareAssetsToEmail = async (assetIds: string[], email: string) => {
		const response = await axios.default.put<{ success: boolean }>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/share/${email}`,
			{ data: JSON.stringify(assetIds) },
			getHeaders()
		);
		return response.data.success;
	};

	const findRecentUploads = async (amount: number = 10) => {
		const mostRecent = await axios.default.get<AssetVersion[]>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/query/byRecent/${amount}`,
			getHeaders()
		);
		if (mostRecent?.data) return mostRecent.data;
	};

	const zipMultiple = (data: object): Promise<{ url: string }> => {
		return new Promise(async (resolve, rej) => {
			try {
				const response = await axios.default.post<{ url: string }>(
					`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/zipMultiple`,
					{ data: JSON.stringify(data) },
					getHeaders()
				);
				return resolve(response.data);
			} catch (e) {
				_logError(e);
				return rej(e);
			}
		});
	};

	const updateOne = async (id: string, updatedAsset: AssetVersion) => {
		const patchedAsset = await axios.default.patch<AssetVersion>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/${id}`,
			updatedAsset,
			getHeaders()
		);
		return patchedAsset.data;
	};
	const deleteOne = async (id: string, workflowId?: string, md5?: string) => {
		if (!workflowId) {
			const deleted = await axios.default.delete<AssetVersion>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/${id}`,
				config
			);
			return deleted.status === 200;
		} else if (md5) {
			const deleted = await axios.default.delete<AssetVersion>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/${id}/workflow/${workflowId}/md5/${md5}`,
				config
			);
			return deleted.status === 200;
		} else {
			const deleted = await axios.default.delete<AssetVersion>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/${id}/workflow/${workflowId}`,
				config
			);
			return deleted.status === 200;
		}
	};

	/**
	 * Avoid double slashes.
	 * @param path Path relative to the API root.
	 */
	const buildPath = (path: string) => {
		return `${endpoint}/${path.replace(endpoint, '').replace(/^\//, '')}`;
	};

	/**
	 * @param path Asset version path to fetch from API for signed URL for download
	 * @returns {string} signed URL
	 */
	const getForRedirect = async (path: string): Promise<string> => {
		const response = await axios.default.get(path, config);
		if (response.data) return new URL(response.data).toJSON();
		throw new Error('Getting for a redirect, but none was provided');
	};

	const getNewSignedUrl = async (
		asset: AssetVersion,
		workflowId?: string,
		md5?: string
	) => {
		if (!asset.path) {
			return Promise.resolve('');
		}
		let path: string = !workflowId
			? buildPath(`assets/${asset._id}/data`)
			: buildPath(`assets/${asset._id}/workflow/${workflowId}/data`);

		if (!workflowId) path = buildPath(`assets/${asset._id}/data`);
		else if (md5 && workflowId)
			path = buildPath(
				`assets/${asset._id}/workflow/${workflowId}/md5/${md5}/data`
			);
		else if (workflowId)
			path = buildPath(`assets/${asset._id}/workflow/${workflowId}/data`);

		return await getForRedirect(path);
	};

	const downloadAssetFile = async (asset: AssetVersion) => {
		const href = window.location.href;
		let signedURL;
		if (!href.includes('workflows')) {
			signedURL = await getNewSignedUrl(asset);
		} else {
			const wfId = href.slice(
				href.indexOf('workflows') + 10,
				href.indexOf('assets') - 1
			);

			signedURL = await getNewSignedUrl(asset, wfId, asset.md5);
		}

		window.open(
			signedURL,
			asset._id /* todo add features to hide resulting window? */
		);
	};

	const findMissingDamAssetsOnStage = async (
		stageId: string,
		workflowId: string
	) => {
		const res = await axios.default.get<string[]>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/damAsset/` +
				stageId +
				'/workflowId/' +
				workflowId,
			getHeaders()
		);

		return res.data;
	};

	const doesPathExist = async (path: String) => {
		const spiceyPath = path.split('/').join('⚛');

		const res = await axios.default.get<string>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/exists/${spiceyPath}`,
			getHeaders()
		);

		return res.data;
	};

	const refreshPreview = async (
		asset: AssetVersion,
		prefix: string
	): Promise<string> => {
		const res = await axios.default.put<string>(
			`${endpoint}/preview/refresh/${prefix}/${asset._id}`,
			{},
			getHeaders()
		);
		return res.data;
	};

	const relatedAssets = async (asset: AssetVersion, workflowId?: string) => {
		if (!workflowId) return [];
		const idsPromise = await axios.default.get(
			`${endpoint}/assets/by-workflow/${workflowId}`,
			getHeaders()
		);
		return idsPromise.data.filter((id: string) => id !== asset._id);
	};

	const zippedAssets = async (asset: AssetVersion) => {
		const idsPromise = await axios.default.get(
			`${endpoint}/assets/query/zippedAssets/${asset._id}`,
			getHeaders()
		);
		return idsPromise.data;
	};

	const listVersions = async (assetId: string) => {
		const versionsPromise = await axios.default.get(
			`${endpoint}/assets/versions/${assetId}`,
			getHeaders()
		);
		return versionsPromise.data;
	};

	const postFile = async (path: string, file: File, data?: object) => {
		const formBody = new FormData();

		formBody.set('file', file, file.name);
		if (data) {
			formBody.append('data', JSON.stringify(data));
		}
		const res = await axios.default.post(
			buildPath(path),
			formBody,
			getHeaders()
		);
		return res.data;
	};

	const postFiles = async (path: string, files: File[], data?: Array<any>) => {
		const formBody = new FormData();
		files.forEach((file) => {
			formBody.append('photos[]', file, file.name);
		});
		if (data) {
			formBody.set('data', JSON.stringify(data));
		}
		const res = await axios.default
			.post(buildPath(path), formBody, {
				...config,
				onUploadProgress: (progressEvent: any) => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);

					updateUploadProgress({
						loaded: loaded,
						total: total,
						percent: percent,
					});
				},
			})
			.finally(() => {
				clearUploadProgress();
			});
		return res.data;
	};

	const uploadFileForWorkflow = async (
		file: File,
		data: AssetVersionUploadData,
		workflow: Workflow,
		stage: Stage,
		inputSlot: InputSlot
	) => {
		return await postFile(
			`/workflows/${workflow._id}/stages/${stage._id}/assets/${inputSlot._id}`,
			file
		);
	};

	const uploadCustomPreview = async (file: File, data: AssetVersion) => {
		return await postFile('assets/upload/custom/preview', file, data);
	};

	const uploadNonWorkflowFiles = async (
		files: File[],
		data: AssetVersionUploadData[]
	) => {
		return await postFiles('/assets/upload/to/dam', files, data);
	};

	const clearExistingPreview = async (asset: AssetVersion) => {
		const res = await axios.default.put<AssetVersion>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/clear/preview`,
			{ asset: asset },
			getHeaders()
		);
		return res.data;
	};

	const assetsByWorkflow = async (assetVersion: AssetVersion) => {
		let response: Maybe<AssetVersion>;

		const res = await axios.default.get<AssetVersion[]>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets`,
			getHeaders()
		);
		res.data
			.reduce((acc: Multimap<string, AssetVersion>, asset: AssetVersion) => {
				if (asset.workflowId) {
					acc.add(getId(asset.workflowId), asset);
				}
				return acc;
			}, new Multimap<string, AssetVersion>())
			.forEach((damAsset: AssetVersion[]) =>
				damAsset.forEach((asset, index) => {
					if (asset.versionId && asset.versionId === assetVersion._id) {
						response = damAsset[index];
					}
				})
			);
		return response;
	};

	const canEditAssetCollection = (collection: AssetCollection, user: User) =>
		roleIsAtLeast(UserRole.WaaAdmin, user.roleId) ||
		!!collection?.owners?.some((m) => m._id === user._id);

	return {
		findOne,
		findMissingDamAssetsOnStage,
		updateOne,
		deleteOne,
		removeAssetsFromDB,
		removeAssetFromCollection,
		deletePreviewFromStorage,
		getNewSignedUrl,
		downloadAssetFile,
		shareAssetToEmail,
		shareAssetsToEmail,
		uploadFileForWorkflow: uploadFileForWorkflow,
		assetsByWorkflow,
		relatedAssets,
		refreshPreview,
		clearExistingPreview,
		zipMultiple,
		uploadVersion,
		uploadNonWorkflowFiles,
		findVersion,
		uploadCustomPreview,
		canEditAssetCollection,
		getForRedirect,
		findRecentUploads,
		listVersions,
		zippedAssets,
		downloadAssetPdf,
		downloadFileWithinAsset,
		replaceAsset,
		runAssetTest,
		runAssetPreviewReport,
		runAssetCompare,
		updateAssetMeta,
		doesPathExist,
		removeIndexes,
	};
};
