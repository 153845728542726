import React, { Profiler, Suspense } from 'react';
import { Redirect, Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import { authProvider, AuthContext, NewAuthStore } from './utils/core';
import AdminLayout from './pages/Admin';
import AuthLayout from './pages/Auth';
import GlobalStyle from './core-ui/GlobalStyle';
import LoginCallback from './pages/views/login-callback';
import { themeStore } from './core-ui/models/ThemeStore';

import './core-ui/styles/App.scss';
import { AwaitingApproval } from './components/accounts/AwaitingApproval';
import FileSharingPoint from 'components/file-sharing/components/file-sharing-point';
import { PageHistoryProvider } from 'context/PageHistoryContext';
import { ActiveUsersContextProvider } from 'context/ActiveUserContext';
import { UploadContextProvider } from 'context/file-upload-provider.component';
import { GroupContextProvider } from 'context/GroupContext';
import { SidebarContextProvider } from 'context/HideSidebarContext';
import { NotificationsContextProvider } from 'context/Notification.context';
import { RoleContextProvider } from 'context/PermissionsContext';
import { AccountsContextProvider } from 'context/useAccounts';
import { FileShareContextProvider } from 'context/useFileShare';
import { MetadataContextProvider } from 'context/useMetadataContext';
import { QueryStoreContextProvider } from 'context/useQueryStore';
import { ThemeContextProvider } from 'context/useThemeContext';
import { WorkflowStoreContextProvider } from 'context/useWorkflowStore';
import { UserContextProvider } from 'context/AuthContext';
import { Layout } from 'Layout';
import { SiteSettingsContextProvider } from 'hooks/useSiteSettings';
process.env.CI = false;
export class AppProfiler extends React.Component {
	onRenderCallback = (
		id, // the "id" prop of the Profiler tree that has just committed
		phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
		actualDuration, // time spent rendering the committed update
		baseDuration, // estimated time to render the entire subtree without memoization
		startTime, // when React began rendering this update
		commitTime, // when React committed this update
		interactions // the Set of interactions belonging to this update
	) => {
		console.table(
			'id',
			'phase',
			'actualDuration',
			'baseDuration',
			'startTime',
			'commitTime',
			'interactions'
		);
		console.table(
			id,
			phase,
			actualDuration,
			baseDuration,
			startTime,
			commitTime,
			interactions
		);
	};

	render() {
		return (
			<Profiler id="test" onRender={this.onRenderCallback}>
				{this.props.children}
			</Profiler>
		);
	}
}
const App = () => {
	const authContext = NewAuthStore();
	const { handleAuthentication } = authContext;

	React.useEffect(() => {
		(function (h, o, t, j, a, r) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = { hjid: 2675002, hjsv: 6 };
			a = o.getElementsByTagName('head')[0];
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
	}, []);

	const authenticate = React.useCallback(
		async () => handleAuthentication(),
		// eslint-disable-next-line
		[]
	);
	return (
		<Layout>
			<ThemeProvider theme={themeStore.selectedTheme}>
				<GlobalStyle />
				<AuthContext.Provider value={authProvider}>
					<ActiveUsersContextProvider>
						<SiteSettingsContextProvider>
							<SidebarContextProvider>
								<ThemeContextProvider>
									<QueryStoreContextProvider>
										<UserContextProvider>
											<RoleContextProvider>
												<GroupContextProvider>
													<UploadContextProvider>
														<NotificationsContextProvider>
															<WorkflowStoreContextProvider>
																<MetadataContextProvider>
																	<AccountsContextProvider>
																		<FileShareContextProvider>
																			<PageHistoryProvider>
																				<></>
																				<Router>
																					<FileSharingPoint path="/filesharing/:fileShareId/:recipient_id" />
																					<Redirect
																						noThrow
																						from="/"
																						to="/auth"
																					/>
																					<AwaitingApproval path="/needs-approval" />
																					<AdminLayout path="admin/*" />
																					<React.Fragment>
																						<AuthLayout default path="/" />
																						<AuthLayout path="/auth/*" />
																					</React.Fragment>
																					<LoginCallback
																						handleAuthentication={authenticate}
																						path="/callback"
																					/>
																				</Router>
																			</PageHistoryProvider>
																		</FileShareContextProvider>
																	</AccountsContextProvider>
																</MetadataContextProvider>
															</WorkflowStoreContextProvider>
														</NotificationsContextProvider>
													</UploadContextProvider>
												</GroupContextProvider>
											</RoleContextProvider>
										</UserContextProvider>
									</QueryStoreContextProvider>
								</ThemeContextProvider>
							</SidebarContextProvider>
						</SiteSettingsContextProvider>
					</ActiveUsersContextProvider>
				</AuthContext.Provider>
			</ThemeProvider>
		</Layout>
	);
};
export default App;